import 'vite/modulepreload-polyfill';
import '/styles/stoffenmanager/public.scss';

import registerMagics from '/magics';
import { registerPublicComponents } from "/components";
import xTextDirective from "/directives/x-text.js";
import xKeyDirective from "/directives/x-key.js";
import { isolate } from '/plugins/isolate.js';

/* Initiate Alpine Modules */
document.addEventListener('alpine:init', () => {
  // Alpine directives
  xTextDirective();
  xKeyDirective();

  // Add additional methods to Alpine global
  Alpine.isolate = isolate;
  registerMagics();
  registerPublicComponents();
});